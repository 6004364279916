import { getAssetPublicUriById } from "~/entities/file";
import { type IItemsGateway, Item } from "~/entities/item";
import { iocContainer } from "~/inversify.config";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";

const CollectionName = "system_content_auth_page";

export class SystemContentAuthPageItem extends Item {
  get bannerUri(): string | null {
    const imageId: string | null = this.getDataProperty("banner") || null;
    if (!imageId) {
      return null;
    }

    return getAssetPublicUriById(imageId);
  }
}

export async function fetchSystemContentAuthPage(): Promise<SystemContentAuthPageItem> {
  const itemsGateway = iocContainer.get<IItemsGateway>(INJECT_SYMBOLS.IItemsGateway);

  try {
    const item = await itemsGateway.getSingletoneByQuery(CollectionName);

    return new SystemContentAuthPageItem(item.collection, item.data, item.meta);
  } catch (err) {
    throw err;
  }
}
