<template>
  <RequestPhoneCode
    v-if="currentStep === AuthStep.request_phone_code"
    @success:codeRequest="onSuccessCodeRequest"
  />

  <EnterPhoneCode
    v-else-if="currentStep === AuthStep.enter_phone_code && codeRequestResult"
    :codeRequestResult="codeRequestResult"
  />
</template>

<script setup lang="ts">
  import { ref } from "vue";
  import { type AuthCodeRequestResponseSuccess } from "~/entities/authentication";
  import { RequestPhoneCode, EnterPhoneCode } from "../ui";

  enum AuthStep {
    "request_phone_code" = "request_phone_code",
    "enter_phone_code" = "enter_phone_code",
  }

  const currentStep = ref<AuthStep>(AuthStep.request_phone_code);
  const codeRequestResult = ref<AuthCodeRequestResponseSuccess | null>(null);

  const onSuccessCodeRequest = (value: AuthCodeRequestResponseSuccess) => {
    codeRequestResult.value = value;
    currentStep.value = AuthStep.enter_phone_code;
  };
</script>
