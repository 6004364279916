<template>
  <form @submit.prevent="submitHandler">
    <div class="mt-8 intro-x">
      <Message
        v-if="!!errorMessage"
        class="w-full h-full text-left"
        :closable="false"
        severity="error"
      >
        {{ $t("error.auth.code_sending") }}: {{ errorMessage }}
      </Message>

      <FormInput
        v-model="phone"
        type="text"
        class="block px-4 py-3 intro-x login__input min-w-full xl:min-w-[350px]"
        :class="{ 'border-danger': phoneError && phoneMeta.touched }"
        :placeholder="$t('phone')"
        mask="+7 999 999 99 99"
        unmask
        @focus="() => setPhoneTouched(true)"
        @blur="() => setPhoneTouched(true)"
      />

      <div v-if="phoneError" class="mt-2 text-danger">{{ phoneError }}</div>
    </div>

    <div class="mt-5 text-center intro-x xl:mt-8 xl:text-left">
      <Button
        type="submit"
        variant="primary"
        class="w-full px-4 py-3 align-top xl:w-32 xl:mr-3"
        :loading="isLoading"
        :disabled="phoneError"
      >
        {{ $t("get_code") }}
      </Button>
    </div>
  </form>
</template>

<script setup lang="ts">
  import { useNuxtApp } from "#app";
  import { computed, ref, onMounted, onBeforeUnmount, watch } from "vue";
  import { object, string } from "zod";
  import { useField, useForm } from "vee-validate";
  import { toTypedSchema } from "@vee-validate/zod";
  import Message from "primevue/message";
  import { fromEvent, filter, Subscription } from "rxjs";
  import { FormInput } from "~/shared/ui/Form";
  import Button from "~/shared/ui/Button";
  import { toaster } from "~/service/toaster";
  import { iocContainer } from "~/inversify.config";
  import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
  import type {
    AuthByPhoneServiceInterface,
    AuthCodeRequestDto,
    AuthCodeRequestResponseSuccess,
  } from "~/entities/authentication";
  import { generateProofKey } from "~/shared/lib/auth";

  const emit = defineEmits<{
    "success:codeRequest": [value: AuthCodeRequestResponseSuccess];
  }>();

  const isLoading = ref<boolean>(false);
  const errorMessage = ref<string | null>(null);

  const authService = iocContainer.get<AuthByPhoneServiceInterface>(
    INJECT_SYMBOLS.AuthByPhoneServiceInterface,
  );

  const {
    $i18n: { t },
  } = useNuxtApp();

  const validationSchema = toTypedSchema(
    object({
      phone: string()
        .nonempty(t("validation_not_empty_value"))
        .regex(
          /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/gm,
          t("validation_not_valid_phone"),
        ),
    }),
  );

  const { validate } = useForm({
    validateOnMount: false,
    validationSchema,
  });

  const {
    value: phone,
    errorMessage: phoneError,
    meta: phoneMeta,
    setTouched: setPhoneTouched,
  } = useField<string>("phone", undefined, {
    initialValue: "",
  });

  watch(
    () => phoneMeta.touched,
    async () => {
      await validate();
    },
  );

  const proofKey = generateProofKey();

  const data = computed<AuthCodeRequestDto>(() => {
    return {
      phone: "+7" + phone.value,
      proof_key: proofKey,
    };
  });

  const submitHandler = async () => {
    try {
      errorMessage.value = null;
      isLoading.value = true;

      await validate();

      if (phoneError.value) {
        toaster().error(t("validation_not_valid_phone"));
        return;
      }

      const response = await authService.authByPhone(data.value);
      if ("error_message" in response) {
        errorMessage.value = response.error_message;
        return;
      }

      if (response.proof_key !== proofKey) {
        errorMessage.value = "invalid proof key";
        return;
      }

      emit("success:codeRequest", response);
    } catch (err) {
      errorMessage.value = err.message;
      // toaster().error(`${t("error.auth.common")}: ${err!.message}`);
    } finally {
      isLoading.value = false;
    }
  };

  let subscritpionToKeyup: Subscription | undefined;

  onMounted(() => {
    subscritpionToKeyup = fromEvent<KeyboardEvent>(window, "keyup")
      .pipe(filter((event) => event.code === "Enter" && !isLoading.value))
      .subscribe(async () => {
        await submitHandler();
      });
  });

  onBeforeUnmount(() => {
    subscritpionToKeyup?.unsubscribe();
  });
</script>
