<template>
  <form @submit.prevent="submitHandler">
    <div class="mt-8 intro-x">
      <Message
        v-if="!!errorMessage"
        class="w-full h-full text-left"
        :closable="false"
        severity="error"
      >
        {{ $t("error.auth.invalid_code") }}: {{ errorMessage }}
      </Message>

      <FormInput
        v-model="code"
        type="text"
        class="block text-center tracking-[8px] px-4 py-3 intro-x login__input min-w-full xl:min-w-[350px]"
        :class="{ 'border-danger': errors.code && codeMeta.touched }"
        placeholder="______"
        mask="000000"
        unmask
        @blur="setCodeTouched(true)"
      />
      <template v-if="errors.code">
        <div class="mt-2 text-danger">
          {{ errors.code }}
        </div>
      </template>
    </div>

    <div class="mt-5 text-center intro-x xl:mt-8 xl:text-left">
      <Button
        type="submit"
        variant="primary"
        class="w-full px-4 py-3 align-top xl:w-32 xl:mr-3"
        :loading="isLoading"
        :disabled="errors.code"
      >
        {{ $t("signin") }}
      </Button>
    </div>
  </form>
</template>

<script setup lang="ts">
  import { useNuxtApp, navigateTo } from "#app";
  import { watch, computed, ref, onMounted, onBeforeUnmount } from "vue";
  import { object, string } from "zod";
  import { useField, useForm } from "vee-validate";
  import { toTypedSchema } from "@vee-validate/zod";
  import Message from "primevue/message";
  import { fromEvent, filter, Subscription } from "rxjs";
  import { FormInput } from "~/shared/ui/Form";
  import Button from "~/shared/ui/Button";
  import type {
    AuthByPhoneServiceInterface,
    AuthCodeLoginDto,
    AuthCodeRequestResponseSuccess,
  } from "~/entities/authentication";
  import { toaster } from "~/service/toaster";
  import { iocContainer } from "~/inversify.config";
  import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
  import { RoutePathNames } from "~/app/router.options";

  interface IProps {
    codeRequestResult: AuthCodeRequestResponseSuccess;
  }

  const props = defineProps<IProps>();

  const {
    $i18n: { t },
  } = useNuxtApp();

  const authService = iocContainer.get<AuthByPhoneServiceInterface>(
    INJECT_SYMBOLS.AuthByPhoneServiceInterface,
  );

  const isLoading = ref<boolean>(false);
  const errorMessage = ref<string | null>(null);

  const validationSchema = toTypedSchema(
    object({
      code: string().nonempty(t("validation_not_empty_value")),
    }),
  );
  const { errors, validate } = useForm({
    validateOnMount: false,
    validationSchema,
  });

  const {
    value: code,
    meta: codeMeta,
    setTouched: setCodeTouched,
  } = useField<string>("code", undefined, {
    initialValue: "",
  });

  watch(
    () => codeMeta.touched,
    async () => {
      await validate();
    },
  );

  watch(
    () => props.codeRequestResult,
    (data) => {
      const hasStubCode = data.provider_type === "autostub" && !!data.payload.code;
      if (hasStubCode) {
        code.value = String(data.payload.code);
      }
    },
    {
      immediate: true,
    },
  );

  const data = computed<AuthCodeLoginDto>(() => {
    return {
      code: code.value,
      proof_key: props.codeRequestResult.proof_key,
      // todo: добавить значение
      user_id: props.codeRequestResult.user.id,
    };
  });

  const submitHandler = async () => {
    try {
      errorMessage.value = null;
      isLoading.value = true;

      await validate();

      if (errors.value.code) {
        toaster().error(t("validation_not_valid_code"));
        return;
      }

      const response = await authService.authBySms(data.value);
      if ("error_message" in response) {
        errorMessage.value = response.error_message;
        return;
      }

      toaster().success(t("authorisation_success"));

      await navigateTo({
        name: RoutePathNames.HOME,
      });
    } catch (err) {
      errorMessage.value = err.message;
      // toaster().error(`${t("error.auth.common")}: ${err!.message}`);
    } finally {
      isLoading.value = false;
    }
  };

  let subscritpionToKeyup: Subscription | undefined;

  onMounted(() => {
    subscritpionToKeyup = fromEvent<KeyboardEvent>(window, "keyup")
      .pipe(filter((event) => event.code === "Enter" && !isLoading.value))
      .subscribe(async () => {
        await submitHandler();
      });
  });

  onBeforeUnmount(() => {
    subscritpionToKeyup?.unsubscribe();
  });
</script>
