<template>
  <form @submit.prevent="authHandler">
    <PMessage v-if="authNotice !== undefined" :severity="authNotice.severity">
      {{ authNotice.message }}
    </PMessage>

    <div class="mt-8 intro-x">
      <FormInput
        v-model="email"
        type="text"
        class="block px-4 py-3 intro-x login__input min-w-full xl:min-w-[350px]"
        :class="{ 'border-danger': errors.email && emailMeta.touched }"
        :placeholder="$t('email')"
        @blur="setEmailTouched(true)"
      />
      <template v-if="errors.email">
        <div class="mt-2 text-danger">
          {{ errors.email }}
        </div>
      </template>

      <FormInput
        v-model="signinCredentials.password"
        type="password"
        class="block px-4 py-3 mt-4 intro-x login__input min-w-full xl:min-w-[350px]"
        :placeholder="$t('password')"
      />
    </div>

    <div class="flex mt-4 text-xs intro-x text-slate-600 dark:text-slate-500 sm:text-sm">
      <div class="flex items-center mr-auto">
        <FormCheck.Input
          id="remember-me"
          type="checkbox"
          class="mr-2 border"
          checked
          disabled
        />

        <label class="cursor-pointer select-none" htmlFor="remember-me">
          {{ $t("remember_me") }}
        </label>
      </div>

      <Button
        as="a"
        noPadding
        variant="transparent"
        class="text-sm text-primary font-normal"
        @click="emit('changeTab', 'reset_user_password')"
        >{{ $t("forget_the_password") }}</Button
      >
    </div>

    <div class="mt-5 text-center intro-x xl:mt-8 xl:text-left">
      <Button
        type="submit"
        variant="primary"
        :disabled="errors.email || !emailMeta.touched"
        class="w-full px-4 py-3 align-top xl:w-32 xl:mr-3"
        :loading="isAuthLoading"
      >
        {{ $t("signin") }}
      </Button>
    </div>
  </form>
</template>

<script setup lang="ts">
  import { useNuxtApp, navigateTo } from "#app";
  import { ref, onMounted, onBeforeUnmount, watch } from "vue";
  import PMessage from "primevue/message";
  import { fromEvent, filter, Subscription } from "rxjs";
  import { object, string } from "zod";
  import { useField, useForm } from "vee-validate";
  import { toTypedSchema } from "@vee-validate/zod";
  import type { AuthCredentials } from "~/service/auth/types/AuthCredentials";
  import { FormInput, FormCheck } from "~/shared/ui/Form";
  import Button from "~/shared/ui/Button";
  import { RoutePathNames } from "~/app/router.options";
  import { type NoticeMessage, NoticeSeverityEnum } from "~/service/notice/types";
  import { defineNoticeMessage } from "~/service/notice/notices";
  import { iocContainer } from "~/inversify.config";
  import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
  import type { AuthGatewayInterface } from "~/entities/authentication";
  import { toaster } from "~/service/toaster";

  const emit = defineEmits(["changeTab"]);

  const authGateway = iocContainer.get<AuthGatewayInterface>(
    INJECT_SYMBOLS.AuthGatewayInterface,
  );

  const isAuthLoading = ref<boolean>(false);
  const authNotice = ref<NoticeMessage | undefined>(undefined);

  const {
    $i18n: { t },
  } = useNuxtApp();

  const validationSchema = toTypedSchema(
    object({
      email: string()
        .nonempty(t("validation_not_empty_value"))
        .regex(
          new RegExp("^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$"),
          t("validation_not_valid_email"),
        ),
    }),
  );

  const { errors, validate } = useForm({
    validateOnMount: false,
    validationSchema,
  });

  const {
    value: email,
    meta: emailMeta,
    setTouched: setEmailTouched,
  } = useField<string>("email", undefined, {
    initialValue: "",
  });

  watch(
    () => emailMeta.touched,
    async () => {
      await validate();
    },
  );

  const signinCredentials = ref<AuthCredentials>({
    email: email,
    password: "",
  });

  const authHandler = async () => {
    try {
      await validate();

      if (errors.value.email) {
        toaster().error(t("form_has_validation_errors"));
        return;
      }

      authNotice.value = undefined;
      isAuthLoading.value = true;
      const isAuthenticated = await authGateway.authByEmail(signinCredentials.value);

      if (!isAuthenticated) {
        authNotice.value = defineNoticeMessage({
          severity: NoticeSeverityEnum.ERROR,
          message: t("error_unknown_on_login"),
        });
        return;
      }

      await navigateTo({
        name: RoutePathNames.HOME,
      });
    } catch (err) {
      // @ts-expect-error
      toaster().error(`${t("error.auth.common")}: ${err!.message}`);
    } finally {
      isAuthLoading.value = false;
    }
  };

  let subscritpionToKeyup: Subscription | undefined;

  onMounted(() => {
    subscritpionToKeyup = fromEvent<KeyboardEvent>(window, "keyup")
      .pipe(filter((event) => event.code === "Enter" && !isAuthLoading.value))
      .subscribe(async () => {
        await authHandler();
      });
  });

  onBeforeUnmount(() => {
    subscritpionToKeyup?.unsubscribe();
  });
</script>
