<template>
  <div
    class="block grid-cols-2 xl:grid bg-primary xl:bg-white dark:bg-darkmode-800 xl:dark:bg-darkmode-600"
  >
    <div class="flex-col p-5 hidden min-h-screen max-h-screen xl:flex">
      <div class="h-full rounded-[20px] overflow-hidden">
        <Transition name="fade-fast">
          <template v-if="!isLoadingContentItem && !!bannerUri">
            <img
              :src="bannerUri"
              alt="Signin Banner"
              class="w-full h-full object-cover"
            />
          </template>
        </Transition>
      </div>
    </div>

    <div class="flex flex-col justify-between items-center h-screen p-5">
      <div
        class="flex flex-col gap-[40px] pt-[25%] max-w-[420px] w-[420px] justify-self-center"
      >
        <div>
          <h2
            class="text-2xl flex font-bold items-center intro-x xl:text-3xl xl:text-left"
          >
            <Button
              v-if="currentTab !== 'signin'"
              noPadding
              variant="transparent"
              class="mr-3"
              @click="changeTab('signin')"
            >
              <Lucide icon="ArrowLeft"></Lucide>
            </Button>

            {{ $t(currentTab) }}
          </h2>
        </div>

        <Tab.Group v-if="currentTab === 'signin'" class="col-span-2">
          <div class="p-2 tab-box">
            <Tab.List variant="pills">
              <Tab>
                <Tab.Button class="w-full" as="button">
                  {{ $t("email") }}
                </Tab.Button>
              </Tab>

              <Tab>
                <Tab.Button class="w-full" as="button">
                  {{ $t("phone") }}
                </Tab.Button>
              </Tab>
            </Tab.List>
          </div>

          <Tab.Panels>
            <Tab.Panel>
              <AuthByEmail @change-tab="changeTab" />
            </Tab.Panel>

            <Tab.Panel>
              <AuthByPhone />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

        <RequestResetUserPasswordByAuth v-if="currentTab === 'reset_user_password'" />

        <SendAuthFeedback v-if="currentTab === 'feedback'" />

        <div v-if="currentTab !== 'feedback'">
          <Button
            disabled
            noPadding
            as="a"
            variant="transparent"
            class="text-sm font-normal text-primary"
            @click="changeTab('feedback')"
            >{{ $t("need_help") }}</Button
          >
        </div>
      </div>

      <LoginAgreement class="justify-self-end text-center" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useNuxtApp, useHead } from "#app";
  import { ref, computed, onMounted } from "vue";
  import Button from "~/shared/ui/Button";
  import Tab from "~/shared/ui/Tab";
  import { RequestResetUserPasswordByAuth } from "~/features/request-reset-user-password";
  import Lucide from "~/shared/ui/Lucide";
  import { AuthByEmail } from "~/features/auth/by-email";
  import { AuthByPhone } from "~/features/auth/by-phone";
  import { SendAuthFeedback } from "~/features/send-auth-feedback";
  import { createHeadTitleWithPrefix } from "~/shared/lib/head";
  import {
    SystemContentAuthPageItem,
    fetchSystemContentAuthPage,
  } from "~/entities/system-content/auth-page";
  import { logger } from "~/service/logger/logger";
  import { LoginAgreement } from "../ui";

  const {
    $i18n: { t },
  } = useNuxtApp();

  const currentTab = ref("signin");

  const changeTab = (tabName: string) => {
    currentTab.value = tabName;
  };

  useHead({
    title: computed(() => createHeadTitleWithPrefix(t("signin"))),
  });

  const contentItem = ref<SystemContentAuthPageItem | null>(null);
  const isLoadingContentItem = ref<boolean>(false);

  const bannerUri = computed<string | null>(() => contentItem.value?.bannerUri || null);

  onMounted(async () => {
    try {
      isLoadingContentItem.value = true;

      contentItem.value = await fetchSystemContentAuthPage();
    } catch (err) {
      logger().error(
        `[the-login-page]: unable to load system content data. Reason: ${err.message}`,
      );
    } finally {
      isLoadingContentItem.value = false;
    }
  });
</script>
